<template>
  <div v-if="adParams.adClient && adParams.adSlot">
    <!-- The ad-->
    <ins
      ref="googleIns"
      class="adsbygoogle"
      :class="displayAt.toLocaleLowerCase()"
      style="display: block"
      :data-ad-client="adParams.adClient"
      :data-ad-slot="adParams.adSlot"
    ></ins>
  </div>
</template>

<script setup lang="ts">
import { event } from "vue-gtag";
import { AdDisplayAt } from "~/graphql/generated";

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

const props = defineProps<{
  displayAt: AdDisplayAt;
}>();

const emit = defineEmits<{
  (e: "update-ad-status", status: "filled" | "unfilled"): void;
}>();

const host = useCurrentHost();

onMounted(async () => {
  // Wait for the <ins> element to be rendered to the dom
  await nextTick();
  if (window.adsbygoogle) {
    try {
      window.adsbygoogle.push({});
    } catch (e) {
      console.error(e);
    }
  } else {
    try {
      window.adsbygoogle = [{}];
    } catch (e) {
      console.error(e);
    }
  }

  // Check if we have googlefc on the window 500ms after loading. If we don't we can assume the use of an ad blocker.
  setTimeout(() => {
    if (!window.googlefc) {
      emit("update-ad-status", "unfilled");
    }
  }, 500);
});

/*
 * Use a mutation observer to detect changes in the ad status, if the ad is unfilled, emit that so we can load a fallback ad from our own backend
 */
const hasLogged = ref(false);
const googleIns = ref<HTMLElement>();
useMutationObserver(
  googleIns,
  () => {
    if (!googleIns.value) return;
    const adStatus = googleIns.value?.dataset.adStatus;

    if (adStatus === "filled" || adStatus === "unfilled") {
      emit("update-ad-status", adStatus);
    }

    if (!hasLogged.value && adStatus === "filled") {
      event("ad_show", {
        type: "adsense",
      });
      hasLogged.value = true;
    }
  },
  {
    attributes: true,
    attributeFilter: ["data-ad-status"],
  }
);

const isVisible = useElementVisibility(googleIns);

// Log the impression when the ad is visible
watch(
  isVisible,
  (isVisible) => {
    if (isVisible) {
      // Log to Google Analytics only if the ad is filled
      if (googleIns.value?.dataset.adStatus === "filled" && !hasLogged.value) {
        event("ad_show", {
          type: "adsense",
        });
        hasLogged.value = true;
      }
    }
  },
  { immediate: true }
);

/*
 * Ad sizes based on https://support.google.com/admanager/answer/1100453?hl=en
 */
const adParams = computed(() => {
  let adClient;
  let adSlot;

  if (host.value === "allefolders") {
    adClient = "ca-pub-7814118610095655";
    switch (props.displayAt) {
      case AdDisplayAt.HomepageTop:
        adSlot = "6899796364";
        break;
      case AdDisplayAt.HomepageBottom:
        adSlot = "8984949943";
        break;
      case AdDisplayAt.BrochureStart:
        adSlot = "3367745462";
        break;
      case AdDisplayAt.BrochureStartBottom:
        adSlot = "5596231593";
        break;
      case AdDisplayAt.BrochureEndLeft:
        adSlot = "3374364763";
        break;
      case AdDisplayAt.BrochureEndRight:
        adSlot = "8598771281";
        break;
      case AdDisplayAt.BlogDetailTop:
        adSlot = "6922903515";
        break;
      case AdDisplayAt.BlogDetailBottom:
        adSlot = "1639659383";
        break;
      case AdDisplayAt.NewBrochuresWeb:
        adSlot = "6757888579";
        break;
      case AdDisplayAt.Brand:
        adSlot = "3940153542";
        break;
      case AdDisplayAt.Shop:
        adSlot = "6685720475";
        break;
      case AdDisplayAt.BrochureViewerInterface:
        adSlot = "4739896878";
        break;
      default:
        adSlot = null;
    }
  } else if (host.value === "promopromo") {
    adClient = "ca-pub-9832024765354406";
    switch (props.displayAt) {
      case AdDisplayAt.HomepageTop:
        adSlot = "7217223078";
        break;
      case AdDisplayAt.HomepageBottom:
        adSlot = "8885527964";
        break;
      case AdDisplayAt.BrochureStart:
        adSlot = "3441629590";
        break;
      case AdDisplayAt.BrochureStartBottom:
        adSlot = "6698178822";
        break;
      case AdDisplayAt.BrochureEndLeft:
        adSlot = "9016555757";
        break;
      case AdDisplayAt.BrochureEndRight:
        adSlot = "8893266821";
        break;
      case AdDisplayAt.BlogDetailTop:
        adSlot = "5522912119";
        break;
      case AdDisplayAt.BlogDetailBottom:
        adSlot = "4976117203";
        break;
      case AdDisplayAt.NewBrochuresWeb:
        adSlot = "9132770478";
        break;
      case AdDisplayAt.Brand:
        adSlot = "1254280458";
        break;
      case AdDisplayAt.Shop:
        adSlot = "5193525466";
        break;
      case AdDisplayAt.BrochureViewerInterface:
        adSlot = "4005399707";
        break;
      default:
        adSlot = null;
    }
  }

  return { adClient, adSlot };
});
</script>

<style lang="scss" scoped>
/*
   * Ad sizes based on https://support.google.com/admanager/answer/1100453?hl=en
   */
.homepage_top,
.homepage_bottom,
.new_brochures,
.brand,
.shop {
  // "Leaderbord"
  width: 728px;
  height: 90px;

  @include for-small-screen {
    // "Medium rectangle"
    width: 300px;
    height: 250px;
  }
}

.brochure_start,
.brochure_start_bottom,
.brochure_end_left {
  // "Medium rectangle"
  width: 336px;
  height: 280px;
}

.brochure_end_right {
  // "Mobile banner"
  width: 300px;
  height: 50px;

  @include for-small-screen {
    // "Medium rectangle"
    width: 300px;
    height: 250px;
  }
}

.blog_detail_top,
.blog_detail_bottom {
  // "Medium rectangle"
  width: 336px;
  height: 280px;
}

@include for-small-screen {
  .blog_detail_bottom {
    // "Mobile banner"
    width: 300px;
    height: 50px;
  }
}

.brochure_viewer_interface {
  // "Mobile banner"
  width: 300px;
  height: 50px;
}
</style>
